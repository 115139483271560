<template>
  <div id="calendarProfessionals">
    <v-container grid-list-xl fluid>
      <v-layout wrap>
        <v-flex lg12 md12>
          <v-card class="mb-4">
            <v-toolbar color="primary" dark text dense cad>
              <v-toolbar-title class="subheading">{{ $t('calendario.professionals') }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-divider />
            <v-card-text class="">
              <v-layout class="filtroInforme">
                <v-flex xs6>
                  <v-autocomplete
                    v-model="professional"
                    :items="professionals"
                    :label="$t('common.professional')"
                    clearable
                    item-text="name"
                    item-value="id"
                    @change="changeProfessional"
                  />

                </v-flex>
              </v-layout>
              <span v-if="typeof tabla.calendario !== 'undefined'">
                <v-toolbar dense color="transparent">
                  <v-btn icon @click="modificarFecha('before', tabla.calendario.primer_dia_semana)"
                    ><v-icon color="primary">mdi-chevron-left</v-icon></v-btn
                  >
                  <v-spacer />
                  {{
                    $t('common.semana') +
                      ' ' +
                      tabla.calendario.primer_dia_front +
                      ' - ' +
                      tabla.calendario.ultimo_dia_front
                  }}
                  <v-spacer />
                  <v-btn icon @click="modificarFecha('after', tabla.calendario.ultimo_dia_semana)"
                    ><v-icon color="primary">mdi-chevron-right</v-icon></v-btn
                  >
                </v-toolbar>
                <tabla-calendar
                  :selectors="professionals"
                  :label_selector="$t('common.centro')"
                  :loading="loading"
                  :headers-tabla="tabla.headers"
                  :items-tabla="tabla.body"
                  :professional_id="professional"
                  :dates="tabla.calendario.fecha_completa"
                  class-table="dividirColumnas"
                  @showSnackbarDatePassed="showSnackbarDatePassed"
                />
              </span>
              <v-alert v-else color="grey" :value="true">
                {{ mensaje_precarga }}
              </v-alert>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <div class="text-center">
      <v-dialog v-model="dialogSalirSinGuardar" width="500">
        <v-card>
          <v-card-title class="headline lighten-2">{{ $t('common.titulo_salir_sin_guardar') }}</v-card-title>
          <v-divider />
          <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" dark text @click="dialogSalirSinGuardar = false">{{ $t('common.cancel') }}</v-btn>
            <v-btn color="primary" dark text @click="confirmarSalir">{{ $t('common.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import TablaCalendar from '@/components/common/TablaCalendar.vue';
import { calendarProfessionalIndex, getCalendarProfessional, getHeader } from '@/config/config';

export default {
  components: {
    TablaCalendar,
  },
  data: () => ({
    professionals: [],
    professional: 0,
    tab: 0,
    duracion: 0,
    tabla: { headers: [], body: [] },
    dialogSalirSinGuardar: false,
    pageNext: {},
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    agenda_id: 0,
    dias: [],
    menu: false,
    time: null,
    mensaje_precarga: '',
    agendaModificada: false,
    disabledButton: false,
    arrayEvents: null,
    fecha: '',
    cambiarFecha: '',
    steps: [
      { target: '[data-v-step="0"]', content: '' },
      { target: '[data-v-step="1"]', content: '' },
    ],
  }),
  watch: {},

  mounted: function() {
    this.mensaje_precarga = this.$t('calendario.select_professional');
    this.$http
      .post(calendarProfessionalIndex, { user_id: this.authUser.id }, { headers: getHeader() })
      .then(response => {
        if (response.status === 200 && response.data.estado === 1) {
          this.loading = false;
          this.professionals = response.data.professionals;
          if (this.professionals.length === 1) {
            this.professional = this.professionals[0].id;
            this.changeProfessional();
          }
        }
      });
  },

  methods: {
    resetData() {
      this.tabla = { headers: [], body: [] };
      this.duracion = 0;
      this.professional = 0;
    },

    changeProfessional() {
      if (this.professional > 0) {
        this.loading = true;
        this.$http
          .post(
            getCalendarProfessional,
            {
              user_id: this.authUser.id,
              professional_id: this.professional,
              cambiarFecha: this.cambiarFecha,
              fecha: this.fecha,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.loading = false;
              this.tabla = response.data.agenda;
              this.duracion = response.data.duracion;
            }
          });
      } else {
        this.resetData();
      }
    },

    modificarFecha(accion, fecha) {
      this.fecha = fecha;
      this.cambiarFecha = accion;
      this.changeProfessional();
    },

    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },

    showSnackbarDatePassed() {
      this.$toast.error(this.$t('calendario.datePassed'));
    },
  },

  modificandoDatos(_val) {
    this.agendaModificada = true;
  },

  beforeRouteLeave(to, from, next) {
    if (this.agendaModificada && to['name'] !== 'Login') {
      this.dialogSalirSinGuardar = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>
<style lang="stylus" scoped>
.media
  &-cotent--wrap

  &-menu
    min-width: 260px
    border-right: 1px solid #eee
    min-height: calc(100vh - 50px - 64px);
  &-detail
    min-width:300px
    border-left:1px solid #eee
</style>
<style scoped lang="css">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.v-picker__title primary {
  display: none !important;
}

.tabla_cal th {
  text-align: center;
  color: #27b0e9;
  font-weight: 100;
  font-size: 12px;
}
</style>
