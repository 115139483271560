<template>
  <div id="tablaCalendar">
    <v-data-table
      :headers="headersTabla"
      :items="itemsTabla"
      :loading="loading"
      :loading-text="$t('common.cargando')"
      :items-per-page-options="[10, 25, { text: $t('datatable.all'), value: -1 }]"
      :class="'elevation-1 filtroInforme ' + classTable"
      :items-per-page-text="$t('datatable.rows_per_page')"
      item-key="name"
      :options.sync="pagination"
    >
      <template v-slot:item="{ item, index }">
        <tr>
          <td
            v-for="(i, colIndex) in dias"
            :key="`${index}-${colIndex}`"
            :style="item[i][0].style + ' text-align: center; cursor: pointer;'"
            @click="goToVideo(item[i][0], i)"
          >
            <span v-if="item[i][0].estado === 0" style="font-size: 12px; margin-top: -10px">
              {{ item[i][0].hora }}<br />
            </span>
            <div v-else-if="item[i][0].estado === 1">
              <span style="font-size: 11px">{{ item[i][0].hora }}</span
              ><br />{{ item[i][0].fullName }}
            </div>
            <div v-else-if="item[i][0].estado === 2" style="font-size: 11px">
              {{ $t('calendario.nodisponible') }}
            </div>
          </td>
        </tr>
      </template>

      <template slot="no-data">
        <v-alert :value="true" color="warning" icon="mdi-alert">
          {{ $t('calendario.no_data') }}
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import environment from "@/environment";
import {mapState} from "vuex";

export default {
  name: 'TablaCalendar',
  props: {
    dates: {
      type: Array,
    },
    headersTabla: {
      type: Array,
    },
    itemsTabla: {
      type: Array,
    },
    selectors: {
      type: Array,
    },
    label_selector: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    professional_id: {
      type: Number,
      default: 0,
    },
    classTable: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    pagination: {
      itemsPerPage: 10,
    },
    seleccionado: 0,
    dias: [0, 1, 2, 3, 4, 5, 6],
  }),
  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
    }),
  },
  methods: {
    newVideoconsultationUrl(date, id) {
      if (!environment.portalBoard) {
        return null;
      }
      return `${environment.portalBoard}/videoconsultation?id=${id}&date=${date}`;
    },
    goToVideo(item, day) {
      if (item.estado === 0) {
        const fh = item.dia + ' ' + item.hora;
        const dateAction = new Date(fh.replace(/-/g, '/'));
        if (dateAction - new Date() > -4200000) {
          item.profesional_id = this.professional_id;
          window.localStorage.setItem('videoconsultation_data', JSON.stringify(item));
          this.$router.push('/videoconsulta/nuevo');
        } else {
          this.$emit('showSnackbarDatePassed', true);
        }
      } else if (item.estado === 1) {
        if (this.permissions.newVideoconsultationsPage) {
          window.location.href = this.newVideoconsultationUrl(this.dates[day], item.id);
        } else {
          this.$router.push('/cita/' + item.id);
        }
      }
    },
  },
};
</script>
<style scoped lang="css">
.fuera_rango {
  background: #fef9e7;
}
.rojo {
  background: #f7ece9;
}
.trabajando {
  background: #d5f5e3;
}

.existeCita {
  /*background: #fef9e7;*/
  cursor: pointer;
}

.selectorTabla {
  font-size: 12px;
  margin-top: 10px;
}
.v-label {
  /*font-size: 10px!important;*/
}
.dividirColumnas table th + th {
  border-left: 1px solid #dddddd;
}
.dividirColumnas table td + td {
  border-left: 1px solid #dddddd;
}
</style>

